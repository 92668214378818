<template>
  <a-card>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text, record" style="text-align: right; min-width: 90px; max-width: 100px">
        {{ record.category === 1 ? '-' : (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a
            v-action:import
            @click="createPayVoucher(record)"
            v-if="record.status === 3 && (record.category === 2 || record.category === 3 || record.category === 4) && !record.is_voucher">
            创建凭证
          </a>
        </template>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'
import EditImagesForm from '../modules/AddItemForm'
import ChildIcon from '../modules/ChildIcon'
import Preview from '../modules/preview'
import {
  property_project_item_pay_voucher,
  property_project_item_pay_voucher_list
} from '@/api/property_project_item'

export default {
  name: 'TableList',
  components: {
    EditImagesForm,
    STable,
    CreateForm,
    Preview,
    ChildIcon
  },
  data () {
    return {
      loading: false,
      visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '项目名称',
          dataIndex: 'project_name',
          ellipsis: true
        },
        {
          title: '明细名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '类别',
          dataIndex: 'category',
          customRender: (text) => this.$Dictionaries.property_project_category[text] || '-',
          align: 'center'
        },
        {
          title: '总计',
          dataIndex: 'fee',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '科目',
          dataIndex: 'subject',
          width: 160,
          customRender: (text, record) => record.category === 1 ? '-' : this.$Dictionaries.property_project_fee_subject[text] || ''
        },
        {
          title: '说明',
          dataIndex: 'content',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.property_project_check_status[text] || '',
          align: 'center'
        },
        {
          title: '生成支付凭证',
          dataIndex: 'is_voucher',
          width: 140,
          align: 'center',
          customRender: (text) => text ? '是' : '否'
        },
        {
          width: 150,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return property_project_item_pay_voucher_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      }
    }
  },
  methods: {
    createPayVoucher (record) {
      property_project_item_pay_voucher(record.id).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
